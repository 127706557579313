<template>
  <div>
    <WarReportComp :programDetails="getProgramDetails" />
  </div>
</template>

<script>
import WarReportComp from '@/components/War/Reports/WarReportComp.vue';
import { getProgramDetailsByParams } from '@/components/War/Transaction/warTransaction.util';

export default {
  name: 'WarReport',
  components: {
    WarReportComp,
  },
  computed: {
    getProgramDetails() {
      return [...getProgramDetailsByParams(this)];
    },
  },
};

</script>
