<template>
  <div>
    <v-container >
      <v-row class="elevation-1">
        <v-col>
          <v-select id="tmc-war-report-type"
            class = "war-type-of-report-select"
            v-model="reportConfig"
            :items="reports"
            item-text="description"
            item-value="config"
            label="Type of report"
            @change="reportTypeChanged"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1" id="warReportsTransactionsToDisplayMultiSelect"
        v-if="showReportConfig('showTransactionsToDisplay')">
        <v-col>
          <MultiItemSelect
            class="war-reports-transactions-to-display-multiselect"
            label="Transactions To Display"
            item-text="description"
            item-value="type"
            :item-list="transactionList"
            :items="criteria.transactionsToDisplay"
            @selected-items="selectedTransactions"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFiscalYears')">
        <v-col>
          <v-select id="tmc-war-report-fy"
            class="war-report-by-fy-select"
            v-model="criteria.fy"
            :items="fiscalYearList"
            item-text="name"
            item-value="year"
            label="By Fiscal Year"
            @change="searchByCriteria"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFromToDates')">
        <v-col id="tmc-war-report-from-date">
          <ClarionDateControl
            class="war-report-from-date-show-from-to-dates"
            v-model="criteria.fromDate"
            :isRequired="true" label="From Date" />
        </v-col>
          <v-col>
            <ClarionDateControl
              id="tmcWarReportToDate"
              class="war-report-to-date-show-from-to-dates"
              v-model="criteria.toDate"
              :isRequired="true" label="To Date" />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showToDates')">
        <v-col>
          <ClarionDateControl
            class="war-report-to-date-show-to-dates"
            v-model="criteria.toDate"
            :isRequired="true" label="To Date" />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFunds')">
        <v-col>
          <MultiItemSelect id="warReportsByfundListMultiSelect"
            class="war-reports-by-fund-list-multiselect"
            label="By Fund"
            item-text="fundDesc"
            item-value="_id"
            :item-list="fundList"
            :items="criteria.funds"
            @selected-items="selectedFunds"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showAllFunds')">
        <v-col>
          <MultiItemSelect id="warReportsByfundNumbersMultiSelect"
            class="war-reports-by-fund-numbers-multiselect"
            label="By Fund"
            item-text="fundDesc"
            item-value="fund"
            :item-list="allFYFundList"
            :items="criteria.fundNumbers"
            @selected-items="selectedFundNumbers"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="isClearingFundEnable && showReportConfig('showClearingFunds')">
        <v-col>
          <MultiItemSelect id="warReportsByClearingFundListMultiSelect"
            class="war-reports-by-clearing-fund-list-multiselect"
            label="By Clearing Fund"
            item-text="caption"
            item-value="value"
            :item-list="clearingFundList"
            :items="criteria.clearingFunds"
            @selected-items="selectedClearingFunds"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showDepartments') && !isFundsOnly">
        <v-col>
          <MultiItemSelect id="warReportsByDepartmentMultiSelect"
            class="war-reports-by-department-multiselect"
            label="By Department"
            item-text="deptDesc"
            item-value="_id"
            :item-list="departmentList"
            :items="criteria.departments"
            @selected-items="selectedDepartments"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showAccounts') && !isFundsOnly">
        <v-col>
          <MultiItemSelect id="warReportsByAccountMultiSelect"
            class="war-reports-by-account-multiselect"
            label="By Account"
            item-text="accDesc"
            item-value="_id"
            :item-list="accountList"
            :items="criteria.accounts"
            @selected-items="selectedAccounts"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showStatus')">
        <v-col>
          <MultiItemSelect id="warReportsByStatusMultiSelect"
            class="war-reports-by-status-multiselect"
            label="By Status"
            item-text="description"
            item-value="name"
            :item-list="warTransStatus"
            :items="criteria.status"
            @selected-items="selectedStatus"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
      v-if="showReportConfig('showEft')">
        <v-col>
          <v-select
          id="tmcWarReportEftCodeSelect"
          class="war-reports-eft-select"
          v-model="criteria.eft"
          :items="isEftCodeOptions"
          item-text="caption"
          item-value="value"
          label="EFT"
          >
        </v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1">
        <v-col
          cols="2"
          v-if="showReportConfig('showPrintDetails')">
          <v-checkbox
            id="tmcWarReportPrintDetailsCheckBox"
            class="ttmc-war-report-print-details"
            v-model="criteria.printDetails"
            label="Print Details">
          </v-checkbox>
        </v-col>
        <v-col
          cols="3"
          v-if="showReportConfig('showPrintFrom')"
          >
          <ClarionDateControl
            id="tmcWarReportPrintFromDate"
            class="tmc-war-report-print-from"
            label="Print From"
            v-model="criteria.printFrom"
            :isDisabled="!criteria.printDetails"
          />
        </v-col>
        <v-col
          v-if="showReportConfig('showPageBreakOnFund')">
          <v-checkbox
            id="tmcWarrepotPageBreakOnFundCheckBox"
            class="tmc-war-report-page-break-on-fund"
            v-model="criteria.pageBreakOnFund"
            label="Page Break on Fund">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <ReportChip
          class="tmc-war-report-chip"
          :to="reportConfig.route"
          :queryString="queryString"
          :isValid="validateCriteria"
          caption="View Report"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MultiItemSelect from '@/components/common/base/MultiItemSelect.vue';
import ReportChip from '../../common/base/report/ReportChip.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import { calculateFiscalYears, toShortDate, toFiscalYear } from '../../../util/shared/tmc-global';
import { reportList, queryStringCalc } from './report-util';
import { getRawJwt } from '../../../util/jwt';
import { isEmptyObject } from '../../../util/shared/vue-global';
import { extractClearingFundNumber } from '../Transaction/warTransaction.util';

export default {
  name: 'WarReportComp',
  components: {
    ReportChip,
    ClarionDateControl,
    MultiItemSelect,
  },
  props: {
    programDetails: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    criteria: {
      fy: {},
      funds: [],
      fundNumbers: [],
      departments: [],
      accounts: [],
      status: [],
      fromDate: undefined,
      toDate: undefined,
      printFrom: undefined,
      eft: undefined,
      printDetails: false,
      pageBreakOnFund: false,
      transactionsToDisplay: [],
      program: '',
      clearingFunds: [],
    },
    allFYFundList: [],
    reportConfig: {
      route: '',
      filters: {},
    },
    defaultCriteria: {
      fy: undefined,
    },
    funds: [],
    fundBaseCrudKey: 'sharedFund',
    departmentBaseCrudKey: 'warDepartment',
    accountBaseCrudKey: 'warAccount',
    transactionList: [
      { type: 'a', description: 'Appropriations' },
      { type: 's', description: 'Supplements' },
      { type: 't', description: 'Transfers' },
      { type: 'w', description: 'Warrant' },
    ],
  }),
  created() {
    this.initCriteria();
    this.loadConfigAndAfterAction();
    this.initializeFiscalYears();
    this.loadVariousListsWithDefaultValues();
  },
  mounted() {
    this.defaultCriteria.fy = toFiscalYear(new Date(this.todaysDate));
  },
  computed: {
    ...mapGetters('base/crud', [
      'items',
    ]),
    ...mapState('war/transaction', [
      'regYears',
    ]),
    ...mapGetters([
      'todaysDate',
    ]),
    reports() {
      return [
        ...reportList(),
      ];
    },
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem || {},
      warTransStatus: (state) => state.enums.WarTransStatusEnum,
      devSettingItem: (state) => state.SystemConfig.devSettingItem || {},
    }),
    fundList() {
      const fundObjs = this.items(this.fundBaseCrudKey);
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [...updatedFundObj];
    },
    departmentList() {
      const departmentObjs = this.items(this.departmentBaseCrudKey);
      const updatedDeptObj = departmentObjs.map((d) => ({
        ...d,
        deptDesc: `${d.dept} ${d.description || ''}`,
      }));
      return [...updatedDeptObj];
    },
    accountList() {
      const accountObjs = this.items(this.accountBaseCrudKey) || [];
      const updatedAccObjs = accountObjs.map((a) => ({
        ...a,
        accDesc: `${a.account} ${a.shortDesc || ''}`,
      }));
      return [...updatedAccObjs];
    },
    isEftCodeOptions() {
      return [
        {
          value: null,
          caption: '(any)',
        },
        {
          value: false,
          caption: 'No',
        },
        {
          value: true,
          caption: 'Yes',
        },
      ];
    },
    fiscalYearList() {
      const registerdYears = (this.regYears || {});
      const allowAll = false;
      return calculateFiscalYears(registerdYears, allowAll);
    },
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    queryString() {
      return queryStringCalc({
        reportConfig: this.reportConfig,
        criteria: this.criteria,
        token: this.getAuthToken,
        defaultCriteria: this.defaultCriteria,
        isFundsOnly: this.isFundsOnly,
        devSetting: this.devSettingItem,
      });
    },
    validateCriteria() {
      let isValid = true;
      if (this.criteria.fy == null) {
        isValid = false;
      }
      return isValid;
    },
    getAuthToken() {
      return getRawJwt();
    },
    isClearingFundEnable() {
      return this.warSettingItem.clearingFund;
    },
    clearingFundList() {
      const clearingFundNumWithBank = this.warSettingItem.clearingFundList || [];
      const clearingFundNumOnly = extractClearingFundNumber(clearingFundNumWithBank);
      return clearingFundNumOnly.map((item) => ({ caption: item, value: item }));
    },
  },
  methods: {
    ...mapActions('war/transaction', [
      'loadFiscalRegYears',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('war/department', [
      'loadDepartments',
    ]),
    ...mapActions('war/account', [
      'loadAccounts',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    mapCollectionVal(itemList, itemValue) {
      return [...(itemList || []).map((item) => item[`${itemValue}`])];
    },
    loadFundData(fiscalYear) {
      const sortBy = [
        {
          column: 'fund',
          direction: 'asc',
        },
      ];
      const criteria = { fiscalYear, sortBy };
      this.loadFunds({ criteria, baseCrudKey: this.fundBaseCrudKey }).then(() => {
        this.criteria.funds = this.mapCollectionVal(this.fundList, '_id');
      });
    },
    loadDepartmentData() {
      const criteria = {};
      this.loadDepartments({ criteria, baseCrudKey: this.departmentBaseCrudKey }).then(() => {
        this.criteria.departments = this.mapCollectionVal(this.departmentList, '_id');
      });
    },
    loadAccountData() {
      const criteria = {};
      this.loadAccounts({ criteria, baseCrudKey: this.accountBaseCrudKey }).then(() => {
        this.criteria.accounts = this.mapCollectionVal(this.accountList, '_id');
      });
    },
    loadTransactionList() {
      this.criteria.transactionsToDisplay = this.mapCollectionVal(this.transactionList, 'type');
    },
    loadStatus() {
      this.criteria.status = this.mapCollectionVal(this.warTransStatus, 'name');
    },
    loadClearingFundList() {
      this.criteria.clearingFunds = this.mapCollectionVal(this.clearingFundList, 'value');
    },
    searchByCriteria() {
      this.loadFundData(this.criteria.fy);
    },
    initializeFiscalYears() {
      this.loadFiscalRegYears({ baseCrudKey: this.criteria.program }).then(() => {
        const regYears = this.fiscalYearList || [];
        if (regYears.length > 0) {
          this.criteria.fy = (regYears[regYears.length - 1] || {}).year;
          this.loadFundData(this.criteria.fy);
        }
      }).catch((e) => {
        console.warn(e);
      });
    },
    selectedFunds(val) {
      this.criteria.funds = val;
    },
    selectedFundNumbers(val) {
      this.criteria.fundNumbers = val;
    },
    selectedDepartments(val) {
      this.criteria.departments = val;
    },
    selectedAccounts(val) {
      this.criteria.accounts = val;
    },
    selectedStatus(val) {
      this.criteria.status = val;
    },
    selectedTransactions(val) {
      this.criteria.transactionsToDisplay = val;
    },
    selectedClearingFunds(val) {
      this.criteria.clearingFunds = val;
    },
    showReportConfig(configField) {
      return this.reportConfig.filters[`${configField}`];
    },
    async reportTypeChanged() {
      if (isEmptyObject(this.reportConfig)) return [];
      if (this.reportConfig.filters.showAllFunds === true) {
        await this.loadAllFunds();
      }
      return [];
    },
    async loadAllFunds() {
      const fundObjs = await this.loadFunds({
        criteria: {
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
      });
      this.allFYFundList = this.getUniqueFunds(fundObjs);
      this.criteria.fundNumbers = this.mapCollectionVal(this.allFYFundList, 'fund');
    },
    getUniqueFunds(funds) {
      const uniqueArr = [];
      (funds || []).filter((item) => {
        const i = uniqueArr.findIndex((x) => (x.fund === item.fund));
        if (i <= -1) {
          uniqueArr.push(item);
        }
        return null;
      });
      if ((uniqueArr || []).length > 0) {
        const updatedFundObj = uniqueArr.map((f) => ({
          ...f,
          fundDesc: `${f.fund} ${f.description || ''}`,
        }));
        return [...updatedFundObj];
      }
      return [];
    },
    initCriteria() {
      /* Default criteria values */
      this.criteria.program = (this.programDetails[0] || {}).baseCrudKey;
      this.criteria.fromDate = toShortDate(this.todaysDate);
      this.criteria.toDate = toShortDate(this.todaysDate);
      this.criteria.printFrom = toShortDate(this.todaysDate);
    },
    async loadConfigAndAfterAction() {
      await this.loadConfig({ requiredScope: 'warconfig' });
      if (this.isClearingFundEnable) {
        this.loadClearingFundList();
      }
    },
    loadVariousListsWithDefaultValues() {
      this.loadDepartmentData();
      this.loadAccountData();
      this.loadTransactionList();
      this.loadStatus();
    },
  },
};

</script>
