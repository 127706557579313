import { generateQueryString } from '../../common/base/report/report-util';

export const queryStringCalc = ({
  reportConfig,
  criteria,
  token,
  defaultCriteria,
  isFundsOnly,
  devSetting,
}) => {
  const params = [];

  if (reportConfig.filters.showFromToDates && criteria.fromDate) {
    params.push(`fromDate=${criteria.fromDate}`);
  }

  if ((reportConfig.filters.showFromToDates || reportConfig.filters.showToDates)
    && criteria.toDate) {
    params.push(`toDate=${criteria.toDate}`);
  }

  if (reportConfig.filters.showFiscalYears && criteria.fy) {
    const fy = criteria.fy
      ? criteria.fy : defaultCriteria.fy;
    params.push(`fiscalYear=${fy}`);
  }

  if (reportConfig.filters.showEft) {
    params.push(`eft=${criteria.eft}`);
  }

  if (reportConfig.filters.showPrintDetails) {
    params.push(`printDetails=${criteria.printDetails}`);
  }

  if (reportConfig.filters.showPrintDetails
    && reportConfig.filters.showPrintFrom
    && criteria.printFrom
  ) {
    params.push(`printFrom=${criteria.printFrom}`);
  }

  if (reportConfig.filters.showPageBreakOnFund) {
    params.push(`pageBreakOnFund=${criteria.pageBreakOnFund}`);
  }

  if (reportConfig.filters.showFunds && criteria.funds.length > 0) {
    const funds = criteria.funds.join(',');
    params.push(`funds=${funds}`);
  }

  if (reportConfig.filters.showAllFunds && criteria.fundNumbers.length > 0) {
    const fundNumbers = criteria.fundNumbers.join(',');
    params.push(`fundNumbers=${fundNumbers}`);
  }

  if (reportConfig.filters.showTransactionsToDisplay && criteria.transactionsToDisplay.length > 0) {
    const transactionsToDisplay = criteria.transactionsToDisplay.join(',');
    params.push(`transactionsToDisplay=${transactionsToDisplay}`);
  }

  if (!isFundsOnly) {
    if (reportConfig.filters.showDepartments && criteria.funds.length > 0) {
      const departments = criteria.departments.join(',');
      params.push(`departments=${departments}`);
    }

    if (reportConfig.filters.showAccounts && criteria.accounts.length > 0) {
      const accounts = criteria.accounts.join(',');
      params.push(`accounts=${accounts}`);
    }
  }

  if (reportConfig.filters.showStatus && criteria.status.length > 0) {
    const status = criteria.status.join(',');
    params.push(`status=${status}`);
  }

  if (reportConfig.filters.showClearingFunds && criteria.clearingFunds.length > 0) {
    const clearingFunds = criteria.clearingFunds.join(',');
    params.push(`clearingFunds=${clearingFunds}`);
  }

  if (token) {
    params.push(`token=${token}`);
  }

  if (criteria.program) {
    params.push(`program=${criteria.program}`);
  }

  /* For testcafe */
  if (devSetting && devSetting.isTestEnvironment) {
    params.push('isTestCafe=1');
  }

  return generateQueryString(params);
};

export const reportList = () => {
  const list = [
    {
      description: 'Balance Record FY',
      config: {
        route: 'war/balance-records-fy',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
        },
      },
    },
    {
      description: 'Warrant Register by Fund',
      config: {
        route: 'war/warrant-register-by-fund',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showStatus: true,
          showPrintFrom: true,
          showClearingFunds: true,
        },
      },
    },
    {
      description: 'Warrant Register by Day',
      config: {
        route: 'war/warrant-register-by-day',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showStatus: true,
          showPrintFrom: true,
          showClearingFunds: true,
        },
      },
    },
    {
      description: 'Appropriation Ledger',
      config: {
        route: 'war/appropriation-ledger',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showPrintDetails: true,
          showPrintFrom: true,
          showPageBreakOnFund: true,
          showTransactionsToDisplay: true,
        },
      },
    },
    {
      description: 'Outstanding Warrant Register',
      config: {
        route: 'war/outstanding-warrant',
        filters: {
          showAllFunds: true,
          showDepartments: true,
          showAccounts: true,
          showToDates: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showClearingFunds: true,
        },
      },
    },
    {
      description: 'Warrant Payment Register by Fund',
      config: {
        route: 'war/warrant-payment-by-fund',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showEft: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showPrintFrom: true,
        },
      },
    },
    {
      description: 'Warrant Payment Register by Day',
      config: {
        route: 'war/warrant-payment-by-day',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showEft: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showPrintFrom: true,
        },
      },
    },
    {
      description: 'Cancelled Warrants by Fund',
      config: {
        route: 'war/cancelled-warrants-by-fund',
        filters: {
          showFiscalYears: true,
          showFunds: true,
          showDepartments: true,
          showAccounts: true,
          showFromToDates: true,
          showPrintDetails: true,
          showPageBreakOnFund: true,
          showPrintFrom: true,
          showClearingFunds: true,
        },
      },
    },
  ];
  return list;
};

export default {
  reportList,
  queryStringCalc,
};
